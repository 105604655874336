import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom";
import {
  getAppointmentDetailsById,
  sendPrescriptionToUser,
  sendTestToUser,
} from "../../services/DashboardAsyncFunctions";
import User from "../../images/DummyUser.png";
import RespondToAppointment from "../modal/RespondToAppointment";
import moment from "moment";
import { APPOINTMENTS_EVENTS } from "../../utils/content";
import eventBus from "../../services/Eventbus";
import SelectPharmacy from "../modal/SelectPharmacy";
import PharmacyCard from "../components/cards/PharmacyCard";
import { AiFillExperiment } from "react-icons/ai";
import AddSuggestions from "../modal/AddSuggestions";
import SelectLab from "../modal/SelectLab";
import { selectConversationForChat } from "../../store/actions/SessionActions";
import { useDispatch } from "react-redux";

function AppointmentDetails() {
  const appointmentId = useParams();
  const dispatch = useDispatch();
  const loginType = localStorage.getItem("_anytime_doctor_type_");
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);
  const [requestedPharmacies, setRequestedPharmacies] = useState(null);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [showSuggestionMessage, setShowSuggestionMessage] = useState("");

  const [showLabModal, setShowLabModal] = useState(false);

  const navigate = useHistory();

  async function getAppointmentDetails() {
    if (!appointmentId?.id) {
      toast.error("Could not fetch details");
      return;
    }
    setIsLoading(true);
    try {
      let response = await getAppointmentDetailsById(appointmentId?.id);
      setAppointmentDetails(response?.data?.data);
      setShowResponseModal(false);
      setRequestedPharmacies(response?.data?.data?.pharmacies);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function submitPrescription(prescriptionList, pharmaIdList) {
    setIsLoading(true);
    let payload;
    if (pharmaIdList != null) {
      payload = {
        appointmentId: appointmentId.id,
        pharmacies: [...pharmaIdList],
        prescriptions: [...prescriptionList],
      };
    } else {
      payload = {
        appointmentId: appointmentId.id,
        pharmacies: [],
        prescriptions: [...prescriptionList],
      };
    }

    try {
      const result = await sendPrescriptionToUser(payload);
      if (result.status == 200) {
        toast.success("Prescription sent to user");
        setShowPharmacyModal(false);
        getAppointmentDetails();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function submitTest(testList, labList) {
    setIsLoading(true);
    let payload;
    if (labList != null) {
      payload = {
        appointmentId: appointmentId.id,
        labs: [...labList],
        labTest: [...testList],
      };
    } else {
      payload = {
        appointmentId: appointmentId.id,
        labs: [],
        labTest: [...testList],
      };
    }
    try {
      const result = await sendTestToUser(payload);
      if (result.status == 200) {
        toast.success("Test sent to user");
        setShowLabModal(false);
        getAppointmentDetails();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAppointmentDetails();
    let eventId = eventBus.on(
      APPOINTMENTS_EVENTS.GET_APPOINTMENT_DETAILS,
      getAppointmentDetails
    );
    return () => {
      eventBus.removeListener(eventId);
    };
  }, [showSuggestionMessage]);

  const chatHandler = () => {
    dispatch(
      selectConversationForChat({
        conversationId: appointmentDetails?.conversation,
        name: appointmentDetails?.user?.name,
        Id: appointmentDetails?.user?._id,
        image: appointmentDetails?.user?.image,
        email: appointmentDetails?.user?.email,
      })
    );
    navigate.push("/chat");
  };

  return (
    <>
      <SelectPharmacy
        handleSubmit={submitPrescription}
        appointmentId={appointmentId?.id}
        show={showPharmacyModal}
        onHide={() => setShowPharmacyModal(false)}
      />
      <SelectLab
        handleSubmit={submitTest}
        appointmentId={appointmentId?.id}
        show={showLabModal}
        onHide={() => setShowLabModal(false)}
      />
      {!isLoading && appointmentDetails && (
        <RespondToAppointment
          show={showResponseModal}
          onHide={() => setShowResponseModal(false)}
          appointmentId={appointmentId?.id}
          type={"details"}
        />
      )}
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto w-100 d-flex align-items-center justify-content-between flex-wrap">
          <h2 className="text-black font-w600 mb-0">Appointment Details</h2>
          <div className="col-auto d-flex align-items-start flex-wrap mt-4">
            {appointmentDetails?.status === "Pending" && (
              <button
                className="btn btn-primary p-2 px-3 fs-12 mb-2 mb-md-0"
                onClick={() => setShowResponseModal(true)}
              >
                Respond
              </button>
            )}
            {appointmentDetails?.status === "Rejected" && (
              <button
                className="btn btn-danger p-2 px-3 fs-12 mb-2 mb-md-0"
                disabled
              >
                Rejected
              </button>
            )}
            {appointmentDetails?.status === "Accepted" &&
              loginType === "Doctor" && (
                <>
                  <button
                    className="btn btn-success p-2 px-3 fs-12 mb-2 mb-md-0"
                    disabled
                  >
                    Accepted
                  </button>
                  <button
                    className="btn btn-primary p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={chatHandler}
                  >
                    Chat
                  </button>
                  <Button
                    variant="primary light"
                    className="p-2 px-3 fs-12 ml-2"
                    disabled
                  >
                    Join Call
                  </Button>
                </>
              )}
          </div>
        </div>
      </div>

      {!isLoading && appointmentDetails && (
        <div className="row">
          <Card className="col-11 col-md-5 p-3 flex-row flex-grow-1 mx-auto mb-3">
            <div className="d-flex align-items-start">
              <img
                src={
                  appointmentDetails?.user?.image
                    ? process.env.REACT_APP_BUCKET_BASE_URL +
                      appointmentDetails?.user?.image
                    : User
                }
                alt=""
                className="appointment-details-img"
              />
            </div>
            <div className="row w-100 ml-2">
              <div className="col-6">
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Patient Name
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {appointmentDetails?.user?.name}
                  </div>
                </div>
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Email
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {appointmentDetails?.user?.email}
                  </div>
                </div>
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Gender
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {appointmentDetails?.user?.gender}
                  </div>
                </div>
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Age
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {moment().diff(
                      moment(
                        appointmentDetails?.user?.dateOfBirth,
                        "YYYY-MM-DD"
                      ).toDate(),
                      "years"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Booking For
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {appointmentDetails?.bookingFor == "mySelf"
                      ? "Self"
                      : "Member"}
                  </div>
                </div>
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Method
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {appointmentDetails?.method}
                  </div>
                </div>
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Time Slot
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {moment(appointmentDetails?.timeSlots[0]).format(
                      "DD, MMM yyyy"
                    )}
                    , ({moment(appointmentDetails?.timeSlots[0]).format("LT")})
                  </div>
                </div>
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Phone Number
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {appointmentDetails?.user?.phoneNumber}
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </Card>
          <div className="col-12 col-md-7 flex-grow-1">
            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Prescription</h4>
                  {/* <button  className="btn btn-outline-primary p-2 px-3 fs-12" disabled={appointmentDetails == "Pending"} onClick={()=>setShowPharmacyModal(true)}>Select Pharmacy <i className="flaticon-381-plus"></i> </button> */}
                  {appointmentDetails?.prescriptions?.length == 0 && (
                    <button
                      className="btn btn-outline-primary p-2 px-3 fs-12"
                      disabled={appointmentDetails?.status == "Pending"}
                      onClick={() => setShowPharmacyModal(true)}
                    >
                      Add Prescription <i className="flaticon-381-plus"></i>{" "}
                    </button>
                  )}
                </div>
                <div>
                  {appointmentDetails?.prescriptions &&
                    appointmentDetails?.prescriptions.map((item, index) => (
                      <div
                        key={item._id}
                        className="d-flex align-items-start flex-column mt-3"
                      >
                        <div className="pharma-card_distance mb-2">
                          <span className="font-weight-bold">{index + 1}.</span>{" "}
                          {item.name}
                        </div>
                        <div className="pharma-card_distance">
                          {item.description}
                        </div>
                      </div>
                    ))}
                </div>
              </Card>
            </div>

            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <h4>Pharmacy</h4>
                {appointmentDetails &&
                  appointmentDetails.pharmacies.map((item, index) => (
                    <PharmacyCard
                      key={item._id}
                      handleSelection={() => {}}
                      pharmaId={item._id}
                      selectedPharmacies={[]}
                      name={item.pharmacyName}
                      email={item.email}
                      location={item.address}
                      index={index}
                      type="display"
                    />
                  ))}
              </Card>
            </div>
            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Suggestions</h4>
                  <button
                    className="btn btn-outline-primary p-2 px-3 fs-12"
                    disabled={appointmentDetails?.status == "Pending"}
                    onClick={() => setShowSuggestionModal(true)}
                  >
                    Add <i className="flaticon-381-plus"></i>{" "}
                  </button>
                </div>
                <div>
                  {appointmentDetails?.suggestion && (
                    <div className=" mt-3">
                      <div className="pharma-card_distance mb-2">
                        {appointmentDetails?.suggestion}
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>

            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Lab Test</h4>
                  {/* <button  className="btn btn-outline-primary p-2 px-3 fs-12" disabled={appointmentDetails == "Pending"} onClick={()=>setShowPharmacyModal(true)}>Select Pharmacy <i className="flaticon-381-plus"></i> </button> */}
                  {appointmentDetails?.labTest?.length == 0 && (
                    <button
                      className="btn btn-outline-primary p-2 px-3 fs-12"
                      disabled={appointmentDetails?.status == "Pending"}
                      onClick={() => setShowLabModal(true)}
                    >
                      Add Test <i className="flaticon-381-plus"></i>{" "}
                    </button>
                  )}
                </div>
                <div>
                  {appointmentDetails?.labTest &&
                    appointmentDetails?.labTest.map((item, index) => (
                      <div
                        key={item._id}
                        className="d-flex align-items-start flex-column mt-3"
                      >
                        <div className="pharma-card_distance mb-2">
                          <span className="font-weight-bold">{index + 1}.</span>{" "}
                          {item.name}
                        </div>
                      </div>
                    ))}
                </div>
              </Card>
            </div>

            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <h4>Labs</h4>
                {appointmentDetails &&
                  appointmentDetails.labs.map((item, index) => (
                    <PharmacyCard
                      key={item._id}
                      handleSelection={() => {}}
                      pharmaId={item._id}
                      selectedPharmacies={[]}
                      name={item.labName}
                      email={item.email}
                      location={item.address}
                      index={index}
                      type="display"
                    />
                  ))}
              </Card>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="w-100 h-50 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {showSuggestionModal && (
        <AddSuggestions
          show={showSuggestionModal}
          onHide={() => setShowSuggestionModal(false)}
          appointmentId={appointmentId?.id}
          setShowSuggestionMessage={setShowSuggestionMessage}
        />
      )}
    </>
  );
}

export default AppointmentDetails;
