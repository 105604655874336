import React, { useEffect, useState } from 'react'

/// Images
import MsgBox from './MsgBox'
import { getChatMessageList, getConversationList } from '../../services/DashboardAsyncFunctions'
import toast from "react-hot-toast";
import moment from "moment";
import User from "./../../images/DummyUser.png"
import { Spinner } from "react-bootstrap";
import socketServices from "../../services/SocketServices";
import { useDispatch, useSelector } from 'react-redux'
import { selectConversationForChat } from '../../store/actions/SessionActions'

const Chat = ({ PerfectScrollbar, toggleChatBox, toggleTab }) => {
  const dispatch = useDispatch();

  const [openMsg, setOpenMsg] = useState(false)
  const [messageList, setMessageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chatList, setChatList] = useState(null);
  const [limit] = useState(15);
  const [hasMorePastData, setHasMorePastData] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isChatFetching, setIsChatFetching] = useState(false);

  const selectedConversation = useSelector(state => state.session.selectedConversationDetails);

  async function getUserChatList() {
    setIsLoading(true);
    try {
      const response = await getConversationList({ page: currentPage, limit: limit });
      console.log(response);
      let updatedList = chatList
        ? [...chatList, ...response.data.data.conversations]
        : [...response.data.data.conversations];
      setChatList([...updatedList]);
      setHasMorePastData(
        response.data.data.conversations.length < limit ? false : true
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUserChatList();
  }, [currentPage]);

  function loadMoreChat() {
    setCurrentPage((prev) => prev + 1);
  }

  async function getChatMessages(conversationId) {
    setIsChatFetching(true)
    try {
      const response = await getChatMessageList(conversationId);
      console.log(response);
      setMessageList(response.data?.data?.chat.reverse());
    } catch (error) {

    } finally {
      setIsChatFetching(false)
    }
  }

  async function initialConnect() {
    setMessageList([]);
    if (selectedConversation) {
      setTimeout(() => {
        socketServices.emit("joinConversation", {
          conversationId: selectedConversation?.conversationId,
          userId: "65ddaa3ee21f22008fb49db5",
        });
      }, 500);
    }
    socketServices.on("receiveMessage", (data) => {
      console.log(data);
      setMessageList(prev => [...prev, data])
    });

    socketServices.on("joinedConversation", (data) => {
      console.log(data);
      getChatMessages(data);
    });

    socketServices.on("leavedConversation", (data) => {
      console.log(data);
    });
  }

  function leaveConversation() {
    if (selectedConversation) {
      socketServices.emit("leaveConversation", {
        conversationId: selectedConversation?.conversationId,
        userId: "65ddaa3ee21f22008fb49db5"
      })
    }
    socketServices.removeListener("receiveMessage");
    socketServices.removeListener("joinedConversation");
  }

  useEffect(() => {
    initialConnect();
    return () => leaveConversation()
  }, [selectedConversation]);


  function handleSelectConversation(conversationData) {
    setOpenMsg(true)
    dispatch(
      selectConversationForChat({
        conversationId: conversationData?._id,
        name: conversationData?.receiver?.name,
        Id: conversationData?.receiver?._id,
        image: conversationData?.receiver?.image,
        email: conversationData?.receiver?.email,
      })
    );
  }

  function capitalizeFirstLetterOfFirstWord(phrase) {
    if (phrase.length === 0) return phrase;
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  }


  return (
    <div
      className={`tab-pane fade  ${toggleTab === 'chat' ? 'active show' : ''}`}
      id='chat'
      role='tabpanel'
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box ${openMsg ? 'd-none' : ''
          }`}
      >
        <div className='card-header chat-list-header text-center'>
          <div>
            <h6 className='mb-1'>Chat List</h6>
          </div>
        </div>
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${toggleChatBox ? 'ps ps--active-y' : ''
            }`}
          id='DZ_W_Contacts_Body'
        >
          <ul className='contacts'>
            {chatList && chatList.map((item) => {
              return (<li
                className='active dz-chat-user'
                onClick={() => handleSelectConversation(item)}
                key={item?._id}
              >
                <div className='d-flex justify-content-between align-item-center'>
                  <div className='d-flex bd-highlight align-item-center'>
                    <div className='img_cont'>
                      <img width='40' height="40"
                        src={
                          item.receiver?.image
                            ? process.env.REACT_APP_BUCKET_BASE_URL + item.receiver?.image
                            : User
                        }
                        className='rounded-circle user_img'
                        alt=''
                      />
                      {/* <span className='online_icon'></span> */}
                    </div>
                    <div className="d-flex align-items-start flex-column  ml-1 justify-content-center">
                      <span className="header_name">{capitalizeFirstLetterOfFirstWord(item?.receiver.name)}</span>
                      <span className="header_state">{item.message.substring(0, 20)}{item.message.length > 40 ? "..." : ""}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-end flex-column  ml-3">
                    {item?.unReadMessage > 0 && (
                      <span className="header_unread">{item?.unReadMessage}</span>
                    )}
                    <span className="header_time">{moment(item.updatedAt).fromNow()}</span>
                  </div>
                </div>
              </li>)
            })}
            {isLoading && <div className="d-flex align-items-center justify-content-center mt-2"><Spinner animation="border" variant="info" /> </div>}
            {!isLoading && hasMorePastData && <div className="d-flex align-items-center justify-content-center mt-2">
              <button
                className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                onClick={() => loadMoreChat()}
              >
                Load more
              </button>
            </div>}
          </ul>
        </PerfectScrollbar>
      </div>
      {selectedConversation && <MsgBox
        openMsg={openMsg}
        PerfectScrollbar={PerfectScrollbar}
        offMsg={() => setOpenMsg(false)}
        messageList={messageList}
        isChatFetching={isChatFetching}
      />}
    </div>
  )
}

export default Chat
