import React from 'react'

function TextMessage({ text }) {
  const formattedText = text.replace(/\n/g, '<br>');
  return (
    <div className=''
      dangerouslySetInnerHTML={{ __html: formattedText }}
      style={{ whiteSpace: 'pre-line', maxWidth:"200px" }}
    />
  )
}

export default TextMessage