import AWS from "aws-sdk";
const NEXT_PUBLIC_COGNITO_POOL_ID = "us-east-1:0a255764-dbe8-4fd2-9e9b-cbe34a5693d0"
const NEXT_PUBLIC_COGNITO_POOL_REGION = "us-east-1"
const NEXT_PUBLIC_S3_BUCKET_NAME =  "demandhealthcare"

AWS.config.update({
  region: NEXT_PUBLIC_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: NEXT_PUBLIC_COGNITO_POOL_ID,
  }),
});
 
export default async function addFileToS3Bucket(image, albumName) {
  var file = image;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = albumName + "/";
  var photoKey = albumPhotosKey  + fileName;
 
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: NEXT_PUBLIC_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
    },
  });
 
  var promise = await upload.promise();
  promise.imageName = fileName + ".jpg";
  return promise;
}

