import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function EndVideoCall({ show, onHide, showForm }) {
  const history = useHistory();
  return (
    <>
      <Modal
        className="modal fade"
        backdrop="static"
        centered
        onHide={onHide}
        show={show}
      >
        <div className="animate__animated animate__fadeIn p-3" role="document">
          <Modal.Header className="justify-content-center">
            <h2 className="">End Call</h2>
          </Modal.Header>
          <Modal.Body className="justify-content-center">
            <h4 className="text-center">What would you like to do next?</h4>
          </Modal.Body>
          <Modal.Footer className=" d-flex align-items-center">
            <button
              type="button"
              className="btn btn-success flex-grow-1"
              onClick={() => {
                showForm();
                onHide();
              }}
            >
              Submit Form
            </button>
            <button
              type="button"
              className="btn btn-success flex-grow-1"
              onClick={() => {
                history.push(`/`);
              }}
            >
              Home
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default EndVideoCall;
