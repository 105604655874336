import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getPatientAppointments } from '../../services/DashboardAsyncFunctions';
import { Spinner } from 'react-bootstrap';
import AppointmentCard from '../components/cards/AppointmentCard';

function PatientAppointments() {
  const { id: patientId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState(null);

  async function fetchPatientAppointments() {
    if (!patientId) {
      toast.error("Could not fetch the details");
      return;
    }
    setIsLoading(true);
    try {
      let response = await getPatientAppointments(patientId);
      console.log(response);
      setAppointments(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPatientAppointments();
  }, [])
  return (
    <div className=''>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Patient Appointments</h2>
        </div>
      </div>
      {appointments &&
        appointments.map((item) => (
          <AppointmentCard
            key={item._id}
            patientName={item.user.name}
            status={item.status}
            appointmentId={item._id}
            patientSex={item.user?.gender}
            patientEmail={item.user?.email}
            patientImage={item.user?.image}
            timeSlot={item?.timeSlots?.[0]}
            method={item?.method}
            appointmentType={"patient"}
          />
        ))}
      {!isLoading && appointments && appointments.length == 0 && (
        <div className="text-center">
          <h3>No Data</h3>
        </div>
      )}
      {isLoading && (
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Spinner animation="border" variant="info" />
        </div>
      )}
    </div>
  )
}

export default PatientAppointments