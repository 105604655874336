import React, { useEffect, useState } from "react";
import { Card, Spinner, Tab, Tabs } from "react-bootstrap";
import {
  addAssistantData,
  getAssistantDoctorListData,
} from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import AssistantDoctor from "../components/cards/AssistantDoctor";

const optionsArray = [{ key: "appointments", label: "Appointments" }];

function AddAssistant() {
  const [addLoading, setAddLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  let errorsObj = {
    name: "",
    email: "",
    password: "",
    selectedMultiOptions: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [assistantDoctors, setAssistantDoctors] = useState(null);
  const [key, setKey] = useState("listing");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit] = useState(10);

  console.log(assistantDoctors,"shdfghsgdhfgshdfgsjhdgf")

  async function getAssistantDoctorFunction() {
    setIsLoading(true);
    try {
      let response = await getAssistantDoctorListData(currentPage, limit);
      console.log(response.data);

      let updatedList = assistantDoctors
        ? [...assistantDoctors, ...response.data.data]
        : [...response.data.data];
      setAssistantDoctors([...updatedList]);
      setHasMoreData(response.data.data?.length < limit ? false : true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  function loadMore() {
    setCurrentPage((prev) => prev + 1);
  }

  useEffect(() => {
    getAssistantDoctorFunction();
  }, [currentPage]);

  async function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (name.trim() === "") {
      errorObj.name = "Name is Required";
      error = true;
    }
    if (email.trim() === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password.trim() === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    if (password.length < 6 && password.length > 1) {
      errorObj.password = "Password length must be at least 6 characters long!";
      error = true;
    }
    if (password.length > 15) {
      errorObj.password =
        "Password length must be less than or equal to 15 characters long!";
      error = true;
    }
    if (selectedOptions.length === 0) {
      errorObj.selectedMultiOptions = "Atleast one permission is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    const permissions = selectedOptions.reduce((obj, name) => {
      obj[name] = true;
      return obj;
    }, {});

    try {
      setAddLoading(true);
      const response = await addAssistantData({
        name,
        email,
        password,
        permissions,
      });
      if (response.data.statusCode === 200) {
        setAddLoading(false);
        toast.success("Created successfully.");
        setName("");
        setEmail("");
        setPassword("");
        setSelectedOptions([]);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("User Already Exists");
    } finally {
      setAddLoading(false);
    }
  }

  const handleOnChange = (selected) => {
    setErrors({ ...errors, selectedMultiOptions: "" });
    setSelectedOptions(selected);
  };

  return (
    <div>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Assistant Doctor</h2>
        </div>
      </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="listing" title="List Assistant">
          {assistantDoctors &&
            assistantDoctors.map((item) => (
              <AssistantDoctor
                key={item._id}
                name={item?.name}
                assistantEmail={item?.email}
                lastAppointmentDate={item.updatedAt}
              />
            ))}
          {assistantDoctors && assistantDoctors.length == 0 && (
            <div className="text-center">
              <h3>No Data</h3>
            </div>
          )}
          {isLoading && (
            <div className="d-flex align-items-center justify-content-center mb-3">
              <Spinner animation="border" variant="info" />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {hasMoreData && !isLoading && (
              <button
                className="fs-14 p-2 px-3 btn-warning btn m-auto"
                onClick={() => loadMore()}
              >
                Load more
              </button>
            )}
          </div>
        </Tab>
        <Tab eventKey="add" title="Add Assistant">
          <div className="col-12 col-md-5 flex-grow-1">
            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <form onSubmit={onLogin}>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setErrors({ ...errors, name: "" });
                      }}
                      placeholder="Name"
                    />
                    {errors.name && (
                      <div className="text-danger fs-12">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Email</strong>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setErrors({ ...errors, email: "" });
                      }}
                      placeholder="Email"
                    />
                    {errors.email && (
                      <div className="text-danger fs-12">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Password</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrors({ ...errors, password: "" });
                      }}
                      placeholder="Password"
                    />
                    {errors.password && (
                      <div className="text-danger fs-12">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Select Permission</strong>
                    </label>
                    <div id="multiselect">
                      <DropdownMultiselect
                        options={optionsArray}
                        name="countries"
                        selected={selectedOptions}
                        handleOnChange={handleOnChange}
                        placeholder="Nothing selected"
                        buttonClass="btn-light"
                        optionKey="key"
                        optionLabel="label"
                        selectDeselectLabel="Select/Deselect All"
                        className="form-control"
                      />
                      {errors.selectedMultiOptions && (
                        <div className="text-danger fs-12">
                          {errors.selectedMultiOptions}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      {addLoading ? "Processing..." : "Add"}
                    </button>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AddAssistant;
