/// auth service

export const LOGIN = "serviceProvider/auth/login";

export const CHANGE_PASSWORD = "serviceProvider/auth/changePassword";

export const LOGOUT = "user/auth/logoutDoctor";

export const DASHBOARD = "admin/auth/dashboard";

export const REGISTER_USER = "user/auth/register";

export const APPOINTMENT_LISTING = "serviceProvider/auth/getRequests";

export const RESPOND_TO_REQUEST = "serviceProvider/auth/requestAction";

export const RESPOND_END_CALL_REQUEST = "app/endService";

export const GET_APPOINTMENT_DETAILS = "serviceProvider/auth/request";

export const GET_PHARMACIES = "serviceProvider/auth/getPharmacies";

export const SEND_PRESCRIPTION = "serviceProvider/auth/sendPrescription";

export const SEND_SUGGESTION = "serviceProvider/auth/sendSuggestion";

export const GET_CHAT_MESSAGES = "serviceProvider/auth/getChat";

export const GET_CONVERSATION_LIST = "serviceProvider/auth/getConversations";

export const GET_DASHBOARD_DATA = "serviceProvider/auth/dashboard";

export const GET_PATIENTS = "doctor/patientList";

export const GET_ASSISTANT_DOCTOR_LIST = "doctor/assistantDoctors";

export const GET_PATIENT_APPOINTMENTS = "doctor/userAppointments/";

export const GET_SUGGESTIONS = "doctor/medicineSuggestions";

export const GET_LAB = "serviceProvider/auth/getLab";

export const SEND_TEST = "doctor/addLabTest";

export const ADD_ASSISTANT_DOCTOR = "doctor/assistantDoctor";

export const ADD_MEDICAL_RECORD = "doctor/addMedicalRecord"