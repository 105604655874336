import React, { useLayoutEffect } from 'react'
import { Card } from 'react-bootstrap'
import MessageHeader from './MessageHeader'
import ChatInput from './ChatInput'
import MessageListScroll from './MessageListScroll'
import { BsChatSquareText } from "react-icons/bs";
import { primaryColor } from '../../../utils/content'

function MessagesList({ messageList, isChatFetching, conversationSelectedTrue }) {

  function toggleFunc() {
    var element = document.getElementById("chat-message");
    element.classList.add("mystyle");
  }
  useLayoutEffect(() => {
    if (window.innerWidth < 500) {
      toggleFunc()
    }
  }, [conversationSelectedTrue])

  return (
    <Card id='chat-message' className='message-list shadow-md'>
      {conversationSelectedTrue && <>
        <MessageHeader />
        <MessageListScroll messageList={messageList} isChatFetching={isChatFetching} />
        <ChatInput />
      </>
      }
      {!conversationSelectedTrue && <div className="no-conversation-container">
        <div className="message-container">
          <BsChatSquareText size={"100px"} color={primaryColor} />
          <p className="message">Please select a chat to continue</p>
        </div>
      </div>
      }
    </Card>
  )
}

export default MessagesList