import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import User from "../../../images/DummyUser.png"
import { useDispatch, useSelector } from "react-redux";
import { selectConversationForChat } from "../../../store/actions/SessionActions";

function ChatCard({
  updatedAt,
  lastMessage,
  conversationData,
  unreadCount,
  userName
}) {
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state) => state.session.selectedConversationDetails
  );

  function handleSelectConversation() {
    dispatch(
      selectConversationForChat({
        conversationId: conversationData?._id,
        name: conversationData?.receiver?.name,
        Id: conversationData?.receiver?._id,
        image: conversationData?.receiver?.image,
        email: conversationData?.receiver?.email,
      })
    );
  }

  function capitalizeFirstLetterOfFirstWord(phrase) {
    if (phrase.length === 0) return phrase;
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  }

  return (
    <Card
      className={`chat-card ${selectedConversation?.conversationId == conversationData?._id &&
        "selected"
        }`}
      onClick={handleSelectConversation}
    >
      <img
        src={
          userName?.image
            ? process.env.REACT_APP_BUCKET_BASE_URL + userName.image
            : User
        }
        alt=""
        className="message-header_image"
      />
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-start flex-column  ml-3">
          <span className="header_name">{capitalizeFirstLetterOfFirstWord(userName.name)}</span>
          <span className="header_state">{lastMessage.substring(0, 20)}{lastMessage.length > 40 ? "..." : ""}</span>
        </div>
        <div className="d-flex align-items-end flex-column  ml-3">
          <span className="header_time">{moment(updatedAt).fromNow()}</span>
          {unreadCount > 0 && (
            <span className="header_unread">{unreadCount}</span>
          )}
        </div>
      </div>
    </Card>
  );
}

export default ChatCard;
