import axiosInstance from "../AxiosInstance";
import {
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_USERS,
  GET_USERS,
  GET_USER_DETAILS,
  POST_USER_DETAILS,
  VERIFY_USER_DETAILS,
} from "./UserApiEndPoints";
export function getAllUsers(currentPage, limit, search) {
  return axiosInstance.get(
    GET_USERS + `?page=${currentPage}&limit=${limit}&search=${search}`
  );
}

export function deleteUser(id) {
  return axiosInstance.delete(DELETE_USERS + `?id=${id}`);
}

export function blockUser(id) {
  const postData = { id };
  return axiosInstance.put(BLOCK_USERS, postData);
}

export function approveUser(postData) {
  return axiosInstance.post(APPROVE_USER_DETAILS, postData);
}
export function postUser(
  responseImage,
  fname,
  lname,
  email,
  dob,
  phNumber,
  typeOfTrainerSelected,
  locationSelected,
  servicesSelected,
  years,
  clients,
  noOfCertificate,
  fitnessCertificate,
  password,
  countryCode
) {
  const postData = {
    profile: responseImage,
    fName: fname,
    lName: lname,
    email,
    dob,
    phone: phNumber,
    typeOfTrainer: typeOfTrainerSelected,
    trainningLocation: locationSelected,
    services: servicesSelected,
    years,
    clients,
    noOfCertificate: noOfCertificate,
    fitnessCertificate: fitnessCertificate,
    password,
    countryCode,
  };

  return axiosInstance.post(POST_USER_DETAILS, postData);
}
