import React from 'react'
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import DocumentCard from '../components/cards/DocumentCard';
import PageTitle from '../layouts/PageTitle';


function UpdateDocument() {
  return (
    <>
    {/* <PageTitle pageHeading="Update Documents" /> */}
    <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
           <h3>Update Documents</h3>
            </div>
          </Card.Header>
          <Card.Body>
           <div>
            <input type="file" name="" id="" className='mb-3'/>
            <DocumentCard />
            <DocumentCard />
            <DocumentCard />
               <button className='btn btn-primary' >
                Update
               </button>
           </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

export default UpdateDocument