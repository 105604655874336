import React, { useState } from 'react'
import socketServices from '../../../services/SocketServices';
import { useSelector } from 'react-redux';

function ChatInput() {

  const [inputMessage, setInputMessage] = useState("");
  const selectedConversation = useSelector(state => state.session.selectedConversationDetails);

  function sendMessage(e) {
    e.preventDefault();
    socketServices.emit("sendMessage", {
      message: inputMessage,
      type: "Text",
      conversationId: selectedConversation?.conversationId,
      senderType: "doctor",
      receiverId: selectedConversation?.otherPersonId
    });
    setInputMessage("");
  }

  if (selectedConversation) return (
    <form onSubmit={sendMessage} className='chat-input-container'>
      <input type="text" className='global-input-field w-100 chat-input-container_input' value={inputMessage} placeholder='Type your message' onChange={(e) => setInputMessage(e.target.value)} />
      <button type='submit' className='btn btn-outline-primary chat-input-container_button' disabled={inputMessage.trim() ? false : true}>
        send
      </button>
    </form>
  )
  else return null
}

export default ChatInput