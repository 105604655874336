

export const specialityCategories = [
    { value: "Chronic health disease", label: "Chronic health disease" },
    { value: "Men's health", label: "Men's health" },
    { value: "Women's health", label: "Women's health" },
    { value: "Special Diseases", label: "Special Diseases" },
    { value: "Dermatologist", label: "Dermatologist" },
  ];

  export const SOCKET_GLOBAL_EVENTS = {
    RECIEVE_REQUEST : "receiveRequest",
    NO_RESPONSE_FROM_DOCTOR: "noResponseFromDoctor",
    JOIN_CALL_AFTER_PAYMENT : "joinCall",
    REQUEST_TIMED_OUT: "requestTimeOut"
  };


  export const APPOINTMENTS_EVENTS = {
    GET_UPCOMING_APPOINTMENTS : "getUpcomingAppointments",
    GET_APPOINTMENT_DETAILS : "getAppointmentDetails",

  }


  export const primaryColor = "#0cc0de"

  export const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );