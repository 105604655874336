import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// App

/// Product List

/// Charts

/// Bootstrap



/// Table

/// Form

/// Pages

import Login from "./pages/Login";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserManagement from "./pages/UserManagement";
import UserDetails from "./pages/UserDetails";
import Signup from "./pages/Registration";
import UpdateDocument from "./pages/UpdateDocument";
import Appointments from "./pages/Appointments";
import AppointmentDetails from "./pages/AppointmentDetails";
import Chat from "./pages/Chat";
import Patients from "./pages/Patients";
import PatientAppointments from "./pages/PatientAppointments";
import VideoCall from "./pages/VideoCall";
import AddAssistant from "./pages/AddAssistant";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },


    /// User management

    { url: "user-management", component: UserManagement },
    { url: "user-details", component: UserDetails },
    { url: "appointments", component: Appointments },
    { url: "appointments/:id", component: AppointmentDetails },
    { url: "chat", component: Chat },
    { url: "patients", component: Patients },
    { url: "patients/:id", component: PatientAppointments },
    { url: "video-call/:id", component: VideoCall },
    { url: "add-assistant", component: AddAssistant },

    // { url: "update-documents", component: UpdateDocument },
    /// Apps

    /// Chart

    /// Bootstrap

    /// Shop

    /// Form

    /// table

    /// pages

    // { url: "page-login", component: Login },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
