import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { addMedicalRecord } from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";

function VideoCallFormFill({ onHide, show, appointmentId }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [callData, setCallData] = useState({
    vitals: "",
    chiefOfComplaint: "",
    hpi: "",
    physicalExam: "",
    diagnosis: "",
    assessment: "",
    investigation: "",
    medications: "",
    plan: "",
  });

  async function submit() {
    try {
      setIsLoading(true);
      const response = await addMedicalRecord({ ...callData, appointmentId });
      if (
        response.status &&
        localStorage.getItem("video-call-show") == "true"
      ) {
        toast.success("The medical record is added!");
      } else if (
        response.status &&
        localStorage.getItem("video-call-show") == "false"
      ) {
        toast.success("The medical record is added!");
        history.push(`/`);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      className="modal fade"
      backdrop="static"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Add Medical Record</h3>
              <Button
                variant=""
                className="close"
                onClick={() => {
                  if (localStorage.getItem("video-call-show") == "true") {
                    onHide();
                  } else {
                    history.push(`/`);
                  }
                }}
              >
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <form className="d-flex align-items-start flex-column gap-2">
              <input
                type="text"
                placeholder="Vitals"
                value={callData.vitals}
                onChange={(e) =>
                  setCallData((prev) => ({ ...prev, vitals: e.target.value }))
                }
                className="form-control mr-2"
                name="vitals"
              />
              <input
                type="text"
                placeholder="Chief of complaint"
                value={callData.chiefOfComplaint}
                onChange={(e) =>
                  setCallData((prev) => ({
                    ...prev,
                    chiefOfComplaint: e.target.value,
                  }))
                }
                className="form-control mr-2"
                name="chiefOfComplaint"
              />
              <input
                type="text"
                placeholder="HPI"
                value={callData.hpi}
                onChange={(e) =>
                  setCallData((prev) => ({ ...prev, hpi: e.target.value }))
                }
                className="form-control mr-2"
                name="hpi"
              />
              <input
                type="text"
                placeholder="Physical Exam"
                value={callData.physicalExam}
                onChange={(e) =>
                  setCallData((prev) => ({
                    ...prev,
                    physicalExam: e.target.value,
                  }))
                }
                className="form-control mr-2"
                name="physicalExam"
              />
              <input
                type="text"
                placeholder="Diagnosis"
                value={callData.diagnosis}
                onChange={(e) =>
                  setCallData((prev) => ({
                    ...prev,
                    diagnosis: e.target.value,
                  }))
                }
                className="form-control mr-2"
                name="diagnosis"
              />
              <input
                type="text"
                placeholder="Assessment"
                value={callData.assessment}
                onChange={(e) =>
                  setCallData((prev) => ({
                    ...prev,
                    assessment: e.target.value,
                  }))
                }
                className="form-control mr-2"
                name="assessment"
              />
              <input
                type="text"
                placeholder="Investigation"
                value={callData.investigation}
                onChange={(e) =>
                  setCallData((prev) => ({
                    ...prev,
                    investigation: e.target.value,
                  }))
                }
                className="form-control mr-2"
                name="investigation"
              />
              <input
                type="text"
                placeholder="Medications"
                value={callData.medications}
                onChange={(e) =>
                  setCallData((prev) => ({
                    ...prev,
                    medications: e.target.value,
                  }))
                }
                className="form-control mr-2"
                name="medications"
              />
              <input
                type="text"
                placeholder="Plan"
                value={callData.plan}
                onChange={(e) =>
                  setCallData((prev) => ({ ...prev, plan: e.target.value }))
                }
                className="form-control mr-2"
                name="Plan"
              />
            </form>
          </Card.Body>
          <Card.Footer>
            <button
              className="btn btn-primary w-100"
              onClick={submit}
              disabled={isLoading}
            >
              {isLoading ? "Loading" : "Add Data"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default VideoCallFormFill;
