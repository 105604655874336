import { SELECT_CONVERSATION, SELECT_CONVERSATION_NULL } from "./PostTypes";

export function selectConversationForChat(data) {
    return {
        type: SELECT_CONVERSATION,
        payload: data,
    };
}

export function selectConversationNull() {
    return {
        type: SELECT_CONVERSATION_NULL,
        payload: null,
    };
}