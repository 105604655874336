import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import socketServices from "../../services/SocketServices";
import { useSelector } from "react-redux";
import MessageComponent from "./MessageComponent";

const MsgBox = ({ openMsg, offMsg, messageList, isChatFetching }) => {

   const [toggle, setToggle] = useState(false);
   const bottomRef = useRef(null);
   const [inputMessage, setInputMessage] = useState("");
   const selectedConversation = useSelector(state => state.session.selectedConversationDetails);

   function sendMessage(e) {
      e.preventDefault();
      socketServices.emit("sendMessage", {
         message: inputMessage,
         type: "Text",
         conversationId: selectedConversation?.conversationId,
         senderType: "doctor",
         receiverId: selectedConversation?.otherPersonId
      });
      setInputMessage("");
   }

   function getMessageStyles(sender, senderType = "") {
      if (senderType === "doctor") {
         return {
            styles: {
               justifyContent: "flex-end"
            },
            isMyMessage: true,
         };
      } else {
         return {
            styles: {
               justifyContent: "flex-start",
            },
            isMyMessage: false,
         };
      }
   }
   function isProfilePicShown(fromId, senderType) {
      if (!getMessageStyles(fromId, senderType).isMyMessage) {
         return true;
      } else return false;
   }

   useEffect(() => {
      const timeoutId = setTimeout(() => {
         bottomRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);

      return () => clearTimeout(timeoutId);
   }, [messageList]);


   function capitalizeFirstLetterOfFirstWord(phrase) {
      if (phrase.length === 0) return phrase;
      return phrase.charAt(0).toUpperCase() + phrase.slice(1);
   }

   return (
      <div
         className={`card chat dz-chat-history-box ${openMsg ? "" : "d-none"}`}
      >
         <div className="card-header chat-list-header text-center">
            <Link to={"#"}
               className="dz-chat-history-back"
               onClick={() => offMsg()}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  version="1.1"
               >
                  <g
                     stroke="none"
                     strokeWidth="1"
                     fill="none"
                     fillRule="evenodd"
                  >
                     <polygon points="0 0 24 0 24 24 0 24" />
                     <rect
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) "
                        x="14"
                        y="7"
                        width="2"
                        height="10"
                        rx="1"
                     />
                     <path
                        d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "
                     />
                  </g>
               </svg>
            </Link>
            <div>
               <h6 className="mb-1">{capitalizeFirstLetterOfFirstWord(selectedConversation.otherPersonName)}</h6>
            </div>
            <div className="dropdown">
               <Link to={"#"}
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setToggle(!toggle)}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink"
                     width="18px"
                     height="18px"
                     viewBox="0 0 24 24"
                     version="1.1"
                  >
                     <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                     >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle fill="#000000" cx="5" cy="12" r="2" />
                        <circle fill="#000000" cx="12" cy="12" r="2" />
                        <circle fill="#000000" cx="19" cy="12" r="2" />
                     </g>
                  </svg>
               </Link>
            </div>
         </div>
         <div className="chat-page model-chat-box">
            <Card id='chat-message' className='message-list shadow-md'>
               <div className='message-list-scroll'>
                  {messageList?.length > 0 &&
                     [...messageList].map((item, index) => (
                         <div
                           key={item._id}
                           style={
                              getMessageStyles(item.sender, item?.senderType)
                                 .styles
                           }
                           className={`d-flex align-items-start  gap-2 ${isProfilePicShown(
                              item.sender,
                              item?.senderType
                           )}`}
                        >
                            <MessageComponent
                              key={item?._id || item?.localMessageId}
                              type={item.type}
                              documentLink={item?.documentLink}
                              text={item.message}
                              isMyMessage={
                                 getMessageStyles(
                                    item.sender,
                                    item?.senderType
                                 ).isMyMessage
                              }
                              createdAt={item.messageTime}
                              isLocalMessage={item?._id ? false : true}
                           />
                        </div>
                     ))}
                  {
                     isChatFetching && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="info" /> </div>
                  }
                  <div className="pb-3" ref={bottomRef}></div>
               </div>
            </Card>
         </div>
         <div className="card-footer type_msg">
            <form onSubmit={sendMessage}>
               <div className="input-group">
                  <input
                     type="text"
                     className="form-control"
                     placeholder="Type your message..."
                     value={inputMessage} onChange={(e) => setInputMessage(e.target.value)}
                  />
                  <div className="input-group-append">
                     <button type="submit" className="btn btn-primary" disabled={inputMessage.trim() ? false : true}>
                        <i className="fa fa-location-arrow"></i>
                     </button>
                  </div>
               </div>
            </form>

         </div>
      </div>
   );
};

export default MsgBox;
