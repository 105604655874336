import React from 'react'
import { BsFiletypeDocx } from "react-icons/bs";

function DocuMessage({ text, documentLink }) {
  return (
    <div className=''
      style={{ whiteSpace: 'pre-line', cursor: "pointer", width: "200px" }}
      onClick={() => window.open(documentLink ? documentLink : text , '_blank', 'noopener,noreferrer')}
    ><BsFiletypeDocx size={24} />{" "}{text}</div>
  )
}

export default DocuMessage