import React, { useState } from 'react'
import TextMessage from './TextMessage';
import moment from 'moment';
import DocuMessage from './DocuMessage';

function MessageComponent({ type, text, isMyMessage, createdAt, isLocalMessage,documentLink }) {
  function getMessageElementForType() {
    if (type == "Text") {
      return <TextMessage text={text} />;
    } else {
      return <DocuMessage text={text} documentLink={documentLink}/>;
    }
  }
  return (
    <div className={`d-flex align-items-end flex-column  mb-3`}>
      <div
        className={`message-base shadow-sm  ${isMyMessage
            ? "my-message-border-radius"
            : "message-border-radius"
          } animate__animated ${!isLocalMessage && 'animate__zoomI'} animate__faster `}
        style={{ backgroundColor: isMyMessage ? "#D8F7FF" : "white" }}
      >
        {getMessageElementForType()}
      </div>
      <div className="text-gray-500 message-time text-right font-semibold font-6 mb-1 animate__animated animate__fadeIn">{moment(createdAt).format('LT')}</div>
    </div>
  );
}

export default MessageComponent;