import React, { useEffect, useState } from "react";
import { Card, Col, Modal } from "react-bootstrap";
import PharmacyCard from "../components/cards/PharmacyCard";
import {
  getPharmaciesList,
  sendSuggestion,
} from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

function AddSuggestions({ onHide, show, appointmentId, setShowSuggestionMessage }) {
  const [medicineName, setMedicineName] = useState("");
  const [suggestionLoading, setSuggestionLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault();
    if (!medicineName) {
      toast.error("Enter suggestion");
      return;
    }

    const payload = {
      appointmentId: appointmentId,
      suggestion: medicineName,
    };
    try {
      setSuggestionLoading(true)
      const result = await sendSuggestion(payload);
      setShowSuggestionMessage(medicineName)
      if (result.status === 200) {
        onHide();
        setMedicineName("");
        setSuggestionLoading(false)
        toast.success("Suggestion created successfully.");
      } else {
        onHide();
        setSuggestionLoading(false)
      }
    } catch (error) {
      onHide();
      setSuggestionLoading(false)
      toast.error(error?.response?.data?.message);
    }
  }

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Write Suggestions</h3>
              {/* <button type="button" className="btn-close"  data-dismiss="modal">X</button> */}
            </div>
          </Card.Header>
          <Card.Body>
            <form className="d-flex align-items-center">
              <input
                type="text"
                placeholder="Suggestion"
                value={medicineName}
                onChange={(e) => setMedicineName(e.target.value)}
                className="form-control mr-2"
                name="medicine"
              />
            </form>
          </Card.Body>
          <Card.Footer>
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={handleSubmit}
              disabled={suggestionLoading}
            >
              {suggestionLoading ? "Loading..." : "Submit"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default AddSuggestions;
