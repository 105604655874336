import React from "react";
import Select from "react-select";

function CustomSelect({selectedOption, handleSelectChange, listItems, ...props }) {
  return (
    <Select
      id="select"
      {...props}
      value={selectedOption}
      onChange={handleSelectChange}
      options={listItems}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "#0cc0de" : "rgb(223, 222, 222)", // Primary app color for focused state
          padding: ".8rem .4rem",
          boxShadow:"0 1px 2px rgba(0, 0, 10, 0.1)",
          borderRadius: "6px"
          
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: "white", // Primary accent color for dropdown menu
          border: "1px solid #0cc0de", // Primary app color for menu border
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          color: state.isSelected ? "white" : "black", // Text color based on selection state
          backgroundColor: state.isSelected ? "#0cc0de" : "white", // Primary app color for selected option background, accent color for unselected option background
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: "#333", // Primary app color for selected value text
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: "#0cc0de", // Primary app color for indicator separator
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: "#0cc0de", // Primary app color for dropdown indicator
        }),
        indicatorContainer: (baseStyles) => ({
          ...baseStyles,
          color: "#0cc0de", // Primary app color for dropdown indicators
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: "grey", // Default placeholder color
        }),
      }}
    />
  );
}

export default CustomSelect;
