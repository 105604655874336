import React from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import User from "../../../images/DummyUser.png"

function MessageHeader() {
  const selectedConversation = useSelector(state=> state.session.selectedConversationDetails);

  function capitalizeFirstLetterOfFirstWord(phrase) {
    if (phrase.length === 0) return phrase;
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  }

  return (
    <Card className='message-header shadow-md'>
    {selectedConversation &&  <div className='d-flex align-items-center'>
      <img src={
          selectedConversation.otherPersonImage
            ? process.env.REACT_APP_BUCKET_BASE_URL + selectedConversation.otherPersonImage
            : User
        } alt="" className='message-header_image'/>
       <div className='d-flex align-items-start flex-column  ml-3'>
        <span className='header_name'>{capitalizeFirstLetterOfFirstWord(selectedConversation.otherPersonName)}</span>
        {/* <span className='header_state'>typing...</span> */}
      </div>
      </div>}
    </Card>
  )
}

export default MessageHeader